// TODO: This will be on a "zmyle-client-api-routes" private npm project
export enum GiftcardEndpoints {
  SEARCH = 'retailers/:retailerId/giftcards/search',
  GIFTCARDS = 'retailers/:retailerId/giftcards',
  CREATE_TRANSACTION_INTENT = 'giftcard_transaction_intents',
  CONFIRM_TRANSACTION_INTENT = 'giftcard_transaction_intents/:intentId/confirm',
  SEND_GIFTCARD_TRANSACTION_SLIP = 'giftcard_transactions/:giftcardTransactionId/sendTransactionSlip',
  ACTIVATE = 'giftcards/:giftcardId/activate',
  GET_PDF = 'giftcards/:giftcardId/pdf',
  GET_GIFTCARD = 'giftcards/:giftcardId',
  CREATE_BATCH_ACTIVATION = 'giftcard_batch_activations',
  CONFIRM_BATCH_ACTIVATION = 'giftcard_batch_activations/:batchActivationId/activate',
}

export enum ProfilesEndpoints {
  UPDATE_PROFILE_PICTURE = 'accounts/:userId/updateProfilePicture',
  UPDATE_PROFILE = 'accounts/:userId/updateProfile',
}

export enum AuthEndpoints {
  AUTH = 'auth/login',
  LOGOUT = 'auth/logout',
  WHOAMI = 'accounts/whoami',
  UPDATE_PASSWORD = 'accounts/updatePassword',
  SIGN_UP = 'auth/signup',
  FORGOT_PASSWORD = '/auth/forgotPassword',
  RESET_PASSWORD = '/auth/resetPassword',
  VERIFY_EMAIL = '/auth/verifyEmail',
}

export enum CampaignEndpoints {
  GET_ACTIVE_CAMPAIGNS = 'campaigns/find_active_campaigns',
  CREATE_FUNDS_RESERVATION = 'campaigns/:campaignId/reserve_funds',
}

export enum MarketplacesEndpoints {
  GET_MARKETPLACE = 'marketplaces/:marketplaceId',
}

export enum CompaniesEndpoints {
  GET_COMPANY = 'companies/:companyId',
  UPDATE_COMPANY = 'companies/:companyId',
  COMPANIES = 'companies',
  GET_EMPLOYEES = 'companies/:companyId/employees',
  GET_EMPLOYEES_SEARCH = 'companies/:companyId/employees/search',
  GET_GIFTCARD_SEARCH = 'companies/:companyId/giftcards/search',
  BENEFIT_TRANSACTIONS = 'benefit_transactions',
  AUTOMATED_BENEFITS = 'automated_benefits',
  GET_AUTOMATED_BENEFITS = 'companies/:companyId/automated_benefits',
  JOIN_NETWORK = 'companies/:companyId/networks',
  CREATE_TEAM_MEMBER = 'companies/:companyId/team',
  GET_TEAM_MEMBERS = 'companies/:companyId/team',
  DELETE_TEAM_MEMBER = 'companies/:companyId/team/:userId',
  UPDATE_TEAM_MEMBER = 'companies/:companyId/team/:userId',
  GET_INVOICES = 'companies/:companyId/invoices',
  UPDATE_GIFTCARD_FRONT_PAGE = 'companies/:companyId/settings/giftcardFrontpagePicture',
  GET_DATA_PROCESSING_AGREEMENT = 'companies/:companyId/subscription/dataProcessingAgreement',
  GET_GIFTCARD_DETAILS = 'companies/:companyId/giftcards/details/:giftcardId',
  CREATE_COMPANY_APPLE_WALLET_PICTURE = 'companies/:companyId/settings/giftcardAppleWalletsPicture',
  CREATE_COMPANY_GOOGLE_WALLET_PICTURE = 'companies/:companyId/settings/giftcardGoogleWalletsPicture',
  CREATE_COMPANY_GIFTCARD_PDF_PICTURE = 'companies/:companyId/settings/giftcardPdfPicture',
  GET_UPCOMING_AUTOMATED_BENEFITS = '/companies/:companyId/executable_automated_benefits',
}

export enum AutomatedBenefitsEndpoints {
  GET_AUTOMATED_BENEFIT = 'automated_benefits/:automatedBenefitId',
  UPDATE_AUTOMATED_BENEFIT = 'automated_benefits/:automatedBenefitId',
  GET_DELIVERY_GIFTCARDS = 'automated_benefits/:automatedBenefitId/delivery/giftcards',
  UPDATE_DELIVERY = 'automated_benefits/:automatedBenefitId/delivery',
  STOP_AUTOMATED_BENEFIT = 'automated_benefits/:automatedBenefitId/stop',
  PAUSE_BOOKING = 'automated_benefits/:automatedBenefitId/pause',
  RESUME_BOOKING = 'automated_benefits/:automatedBenefitId/resume',
}

export enum TransactionsEndpoints {
  GET_COMPANY_TRANSACTIONS = 'companies/:companyId/benefit_transactions',
  GET_TRANSACTION = '/benefit_transactions/:transactionId',
  CANCEL_BENEFIT_TRANSACTION = '/benefit_transactions/:transactionId/cancel',
  GET_GIFTCARD_TRANSACTION_PDF = '/benefit_transactions/:transactionId/pdf',
  GET_EMPLOYEE_TRANSACTIONS = '/employees/:employeeId/benefit_transactions',
  UPDATE_TRANSACTION_METADATA = '/transactions/:transactionId/updateMetadata',
  GET_GIFTCARD_BY_TRANSACTION_ID = '/transactions/:transactionId',
}

export enum EmployeeEndpoints {
  GET_EMPLOYEE = '/employees/:employeeId',
  UPDATE_EMPLOYEE = '/employees/:employeeId',
  EMPLOYEES = 'employees',
  EMPLOYEES_IMPORT = 'employees/import',
  RESOLVE_FILE_IMPORTING = 'employees/resolve_file_importing',
  GET_BENEFIT_ALLOWANCES = '/employees/:employeeId/benefit_allowances',
  DELETE_EMPLOYEE = '/employees/:employeeId',
  GET_AUTOMATED_BENEFITS = '/employees/:employeeId/automated_benefits',
  GET_EMPLOYEE_GIFTCARD_DEPOTS_CONNECTION = '/employees/:employeeId/giftcard_depots/:giftcardDepotId',
  LOCK_EMPLOYEE_DEPOT_GIFTCARD = '/employees/:employeeId/giftcard_depots/:giftcardDepotId/depot_cards/:depotcardId/lock',
  UNLOCK_EMPLOYEE_DEPOT_GIFTCARD = '/employees/:employeeId/giftcard_depots/:giftcardDepotId/depot_cards/:depotcardId/unlock',
  ASSIGN_GIFTCARD_TO_DEPOT = '/employees/:employeeId/giftcard_depots/:giftcardDepotId/depot_cards',
  REPLACE_GIFTCARD_TO_DEPOT = '/employees/:employeeId/giftcard_depots/:giftcardDepotId/depot_cards/:depotcardId/replace',
}

export enum NetworksEndpoints {
  GET_NETWORK_SEARCH = 'networks/search',
  GET_NETWORK = 'networks/:networkId',
  GET_NETWORK_STORES = '/networks/:networkId/stores',
  GET_TEAM_MEMBERS = '/networks/:networkId/team',
  UPDATE_TEAM_MEMBER = 'networks/:networkId/team/:userId',
  DELETE_TEAM_MEMBER = 'networks/:networkId/team/:userId',
  CREATE_TEAM_MEMBER = 'networks/:networkId/team',
  GET_COMPANIES_BY_NETWORK = 'networks/:networkId/companies',
  GET_NETWORK_STATISTICS = 'networks/:networkId/statistics',
  GET_NETWORK_STORE_QUERY = 'networks/:networkId/stores',
  GET_NETWORK_ASSOCIATES = 'networks/:networkId/associates',
  GET_API_TENANTS = 'networks/:networkId/api_tenants',
  ADD_API_TENANTS = 'networks/:networkId/api_tenants',
  DELETE_API_TENANTS = 'networks/:networkId/api_tenants/:apiTenantId',
  GET_ALL_FEES_GROUPS = '/networks/:networkId/fees_groups',
  CREATE_FEES_GROUP = '/networks/:networkId/fees_groups',
  UPDATE_STORE_FEES_GROUP = '/networks/:networkId/stores/:storeId/fees_groups',
  EDIT_FEES_GROUP = '/networks/:networkId/fees_groups/:feesGroupId',
  DELETE_FEES_GROUP = '/networks/:networkId/fees_groups/:feesGroupId',
  REQUEST_STORE_PROFILE_PERMISSION = '/networks/:networkId/stores/:storeId/request_profile_permission',
  GET_STORES_PUBLIC_DATA_CSV = '/networks/:networkId/exports/stores',
  GET_RETAILERS_PRIVATE_DATA_CSV = '/networks/:networkId/exports/retailers',
  GET_ACCOUNT_STATEMENTS = '/networks/:networkId/account_statement_periods',
  NETWORK_ANALYTICS = '/networks/:networkId/community_statistics',
  GET_ACCOUNT_STATEMENT_PDF = '/downloads/account_statements/:resourceId/pdf',
}

export enum CategoryEndpoints {
  GET_ALL_CATEGORIES = '/categories/all',
}

export enum RetailerEndpoints {
  GET_RETAILER = '/retailers/:retailerId',
  CREATE_RETAILER = '/retailers',
  UPDATE_RETAILER = '/retailers/:retailerId',
  UPDATE_RETAILER_SETTINGS = '/retailers/:retailerId/settings',
  GET_TEAM_MEMBERS = '/retailers/:retailerId/team',
  CREATE_TEAM_MEMBER = '/retailers/:retailerId/team',
  DELETE_TEAM_MEMBER = '/retailers/:retailerId/team/:userId',
  UPDATE_TEAM_MEMBER = '/retailers/:retailerId/team/:userId',
  GET_RETAILER_TRANSACTIONS = 'retailers/:retailerId/transactions',
  GET_GIFTCARD_TRANSACTIONS = 'retailers/:retailerId/giftcard_transactions',
  SEARCH_RETAILER_TRANSACTION = 'retailers/:retailerId/giftcards/search',
  GET_STORES = '/retailers/:retailerId/stores',
  GET_INVOICES = 'retailers/:retailerId/invoices',
  CREATE_GIFTCARD = 'retailers/:retailerId/giftcards',
  GET_API_KEYS = '/retailers/:retailerId/api_tenants',
  CREATE_API_KEY = '/retailers/:retailerId/api_tenants',
  GET_API_KEY = '/retailers/:retailerId/api_tenants/:api_tenant_id',
  DELETE_API_KEY = '/retailers/:retailerId/api_tenants/:api_tenant_id',
  GET_PARTNER_API_KEYS = '/retailers/:retailerId/partner_keys',
  GET_PARTNER_API_KEY = '/retailers/:retailerId/partner_keys/:partnerKeyId',
  DELETE_PARTNER_API_KEY = '/retailers/:retailerId/partner_keys/:partnerKeyId',
  CREATE_PARTNER_API_KEYS = '/retailers/:retailerId/partner_keys',
  CREATE_RETAILER_APPLE_WALLET_PICTURE = 'retailers/:retailerId/settings/giftcardAppleWalletsPicture',
  CREATE_RETAILER_GOOGLE_WALLET_PICTURE = 'retailers/:retailerId/settings/giftcardGoogleWalletsPicture',
  CREATE_RETAILER_GIFTCARD_PDF_PICTURE = 'retailers/:retailerId/settings/giftcardPdfPicture',
}

export enum StoreEndpoints {
  CREATE_STORE = '/stores',
  GET_STORE = '/stores/:storeId',
  UPDATE_STORE = '/stores/:storeId',
  UPDATE_STORE_MARKETPLACE_SETTINGS = '/stores/:storeId/update_marketplaces_settings',
  DELETE_STORE_FROM_NETWORK = '/stores/:storeId/networks/:networkId',
  EDIT_STORE = '/stores/:storeId/networks/:networkId',
  UPDATE_STORE_PROFILE_PERMISSION = '/stores/:storeId/networks/:networkId/update_profile_permission',
  UPDATE_STORE_TITLE_IMAGE = '/stores/:storeId/titleImage',
  DELETE_STORE_TITLE_IMAGE = '/stores/:storeId/titleImage',
  UPDATE_STORE_LOGO_IMAGE = '/stores/:storeId/logoImage',
  DELETE_STORE_LOGO_IMAGE = '/stores/:storeId/logoImage',
  CREATE_STORE_PICTURES = '/stores/:storeId/pictures',
  UPDATE_STORE_PICTURES_ORDER = '/stores/:storeId/pictures/sort',
  DELETE_STORE_PICTURE = '/stores/:storeId/pictures/:pictureId',
}

export enum DownloadsEndpoints {
  GET_REMOTE_FILE = '/downloads/:filename',
  GET_WHITELIST_ERRORS = '/downloads/whitelists/errors',
}

export enum InvoicesEndpoints {
  GET_INVOICE = '/invoices/:invoiceId',
}
